import React from 'react'
import PropTypes from 'prop-types'
import Group from 'lyjs/es/components/Group'

export const Direction = {
  LEFT: 'left',
  RIGHT: 'right'
}

function LineGroup(props) {
  const {
    span = 8,
    direction = Direction.RIGHT,
    ...otherProps
  } = props

  function renderItem(item, meta) {
    const {
      type: Component
    } = item || {}

    if (!Component) {
      return item
    }

    const {
      index,
      isFirst,
      isLast
    } = meta
    const {
      key,
      props: {
        style: styleProp,
        ...otherProps
      }
    } = item

    const style = direction === Direction.LEFT
      ? isFirst
        ? styleProp
        : {
          ...styleProp,
          marginLeft: span
        }
      : isLast
        ? styleProp
        : {
          ...styleProp,
          marginRight: span
        }

    return (
      <Component
        {...otherProps}
        key={key || index}
        style={style}
      />
    )
  }

  return (
    <Group
      {...otherProps}
      renderItem={renderItem}
    />
  )
}

if (process.env.NODE_ENV !== 'production') {
  LineGroup.propTypes = {
    span: PropTypes.number,
    direction: PropTypes.oneOf(Object.values(Direction))
  }
}

export default LineGroup
