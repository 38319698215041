import React from 'react'
import compose from 'lyjs/es/fp/compose'
import {connect} from 'react-redux'
import {withRouter, Redirect} from 'react-router-dom'
import withProps, {defaultMergeProps} from 'lyjs/es/components/withProps'
import withQuery from 'lyjs/es/components/withQuery'
import Render from 'lyjs/es/components/Render'

import {unauthorize, authorize, requestToken, requestCode} from 'services/store/profile/effects'
import selector from './selector'

function mergeProps(presetProps, ownProps) {
  const {
    history: {
      createLocation
    },
    location: {
      pathname
    },
    match: {
      query
    }
  } = ownProps

  function createRestore(defaultNext = '/') {
    const {
      _next: next = defaultNext,
      ...otherQuery
    } = query

    const location = createLocation({
      pathname: next,
      query: otherQuery
    })

    return location
  }

  function createNext(to = '/signin') {
    const location = createLocation({
      pathname: to,
      query: {
        ...query,
        _next: pathname
      }
    })

    return location
  }

  return {
    ...defaultMergeProps(presetProps, ownProps),
    createRestoreRedirect(defaultNext) {
      const location = createRestore(defaultNext)

      return (
        <Redirect to={location} />
      )
    },
    createNextRedirect(to) {
      const location = createNext(to)

      return (
        <Redirect to={location} />
      )
    },
    createRestore,
    createNext
  }
}

const mapStateToProps = selector
const mapDispatchToProps = {
  authorize,
  unauthorize,
  requestToken,
  requestCode
}

const AuthorizationComposer = compose(
  withRouter,
  withQuery(),
  connect(mapStateToProps, mapDispatchToProps),
  withProps({
    mergeProps
  })
)(Render)

if (process.env.NODE_ENV !== 'production') {
  AuthorizationComposer.displayName = 'AuthorizationComposer'
}

export default AuthorizationComposer
