import React from 'react'
import PropTypes from 'prop-types'
import cs from 'lyjs/es/utils/classes'

import styles from './styles.module.css'

export const Theme = {
  LIGHT: 'light',
  DARK: 'dark'
}

export const Type = {
  HEADLINE1: 'headline1',
  HEADLINE2: 'headline2',
  HEADLINE3: 'headline3',
  HEADLINE4: 'headline4',
  HEADLINE5: 'headline5',
  HEADLINE6: 'headline6',
  SUBTITLE1: 'subtitle1',
  SUBTITLE2: 'subtitle2',
  BODY1: 'body1',
  BODY2: 'body2',
  BUTTON: 'button',
  CAPTION: 'caption',
  OVERLINE: 'overline'
}

export const Align = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  JUSTIFY: 'justify'
}

export const Transform = {
  LOWSERCASE: 'lowsercase',
  UPPERCASE: 'uppercase',
  CAPITALIZE: 'capitalize'
}

function Text(props) {
  const {
    component: Component = 'span',
    theme = Theme.LIGHT ,
    type,
    disabled,
    inherit,
    nowrap,
    pre,
    truncate,
    align,
    transform,
    className: classNameProp,
    ...otherProps
  } = props

  const className = cs(
    classNameProp,
    styles.text,
    {
      [styles.inherit]: inherit,
      [styles.nowrap]: nowrap,
      [styles.pre]: pre,
      [styles.truncate]: truncate
    },
    align && styles[`align-${align}`],
    transform && styles[`transform-${transform}`],
    type && styles[`${type}-${theme}`],
    disabled && styles[`disabled-${theme}`]
  )

  return (
    <Component
      {...otherProps}
      className={className}
    />
  )
}

if (process.env.NODE_ENV !== 'production') {
  Text.propTypes = {
    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    theme: PropTypes.oneOf(Object.values(Theme)),
    type: PropTypes.oneOf(Object.values(Type)),
    disabled: PropTypes.bool,
    inherit: PropTypes.bool,
    nowrap: PropTypes.bool,
    pre: PropTypes.bool,
    truncate: PropTypes.bool,
    align: PropTypes.oneOf(Object.values(Align)),
    transform: PropTypes.oneOf(Object.values(Transform)),
  }
}

export default Text
