import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {simpleFormat} from 'lyjs/es/utils/createFormat'
import {Helmet} from 'react-helmet'
import {Card, Steps, Icon} from 'antd'

import {WEBSITE_NAME} from 'services/constants'
import Text from 'components/Text'
import styles from './styles.module.css'

const {
  Step
} = Steps

function UnderReview(props) {
  const {
    className,
    style
  } = props

  const loading = (
    <Icon
      className={styles.icon}
      type="loading"
    />
  )

  return (
    <Fragment>
      <Helmet>
        <title>{simpleFormat('%s - %s', WEBSITE_NAME, '账号审核中')}</title>
      </Helmet>
      <Card
        className={className}
        style={style}
      >
        <Text
          component="h3"
          type="subtitle1"
          align="center"
        >
          账号审核中
        </Text>
        <Steps
          className={styles.steps}
          direction="vertical"
          current={1}
        >
          <Step
            title="注册账号"
            description="通过手机号注册用户账号"
          />
          <Step
            title="账号审核"
            description="等待管理员审核账号信息"
            icon={loading}
          />
          <Step
            title="开始使用"
            description="解除账号限制，开启所有功能"
          />
        </Steps>
      </Card>
    </Fragment>
  )
}

if (process.env.NODE_ENV !== 'production') {
  UnderReview.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
  }
}

export default UnderReview
