import React from 'react'
import PropTypes from 'prop-types'
import time33Hash from 'lyjs/es/utils/time33Hash'
import {Avatar as AntdAvatar} from 'antd'

function Avatar(props) {
  const {
    color,
    style: styleProp,
    children,
    ...otherProps
  } = props

  let placeholder
  let background = color

  if (typeof children === 'string') {
    placeholder = children.slice(0, 1).toUpperCase()

    if (background === undefined) {
      background = `hsla(${Math.abs(time33Hash(encodeURIComponent(children)) % 360)}, 50%, 50%, .8)`
    }

  } else {
    placeholder = children
  }

  const style = background ? {
    ...styleProp,
    background
  } : styleProp

  return (
    <AntdAvatar
      {...otherProps}
      style={style}
    >
      {placeholder}
    </AntdAvatar>
  )
}

if (process.env.NODE_ENV !== 'production') {
  Avatar.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node
  }
}

export default Avatar
